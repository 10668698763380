import React, {useContext, useEffect} from "react"
import {navigate} from "gatsby";
import AuthContext from "../../components/Context/AuthContext";
import SEO from "../../components/SEO";
import PageTitle from "../../components/PageTitle";
import AccountSummary from "../../components/AccountSummary";
import PageNavigation from "../../components/PageNavigation";
import {ButtonAccountTop} from "../../components/Button";
import Layout from "../../components/Layout";

export default () => {
  const {session} = useContext(AuthContext)

  useEffect(() => {
    if (session === undefined) {
      navigate("/login/")
    }
  }, [session])

  return (
    <Layout>
      <SEO title="ご注文者情報"/>
      <section className="inner">
        <PageTitle>アカウント管理</PageTitle>
        <AccountSummary/>
        <PageNavigation>
          <ButtonAccountTop/>
        </PageNavigation>
      </section>
    </Layout>
  )
}